import { Routes } from '@angular/router';
import { cadastroRoutes } from 'src/app/features/cadastro/cadastro-routes';

export const formularioRoutes: Routes = [
	{
		path: ':hash/tablet',
		pathMatch: 'full',
		data: { ciclico: true },
		children: cadastroRoutes,
	},
	{
		path: ':hash',
		loadComponent: () =>
			import('./formulario.component').then((m) => m.FormularioComponent),
	},
	{
		path: ':hash/:canalColeta',
		loadComponent: () =>
			import('./formulario.component').then((m) => m.FormularioComponent),
	},
];
