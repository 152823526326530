import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-footer',
  standalone: true,
  imports: [],
  templateUrl: './logo-footer.component.html',
  styleUrl: './logo-footer.component.scss'
})
export class LogoFooterComponent {

}
