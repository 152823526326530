import { Routes } from '@angular/router';

export const descadastroRoutes: Routes = [
	{
		path: ':hash',
		loadComponent: () =>
			import('./descadastro.component').then(
				(m) => m.DescadastroComponent
			),
	},
	{
		path: ':hash/:codigoFormulario',
		loadComponent: () =>
			import('./descadastro.component').then(
				(m) => m.DescadastroComponent
			),
	},
];
