import { Routes } from '@angular/router';
import { cadastroRoutes } from '../cadastro/cadastro-routes';
import { descadastroRoutes } from '../descadastro/descadastro-routes';

export const pesquisaRoutes: Routes = [
	{
		path: 'descadastro',
		children: descadastroRoutes,
	},
	{
		path: ':hash',
		loadComponent: () =>
			import('./pesquisa.component').then((m) => m.PesquisaComponent),
	},
	{
		path: ':hash/:canalColeta',
		loadComponent: () =>
			import('./pesquisa.component').then((m) => m.PesquisaComponent),
	},
	{
		path: 'pesquisas/:hash/tablet',
		children: cadastroRoutes,
		data: { ciclico: true },
	},
];
