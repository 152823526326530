import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LogoFooterComponent } from 'src/app/shared/components/logo-footer/logo-footer.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, LogoFooterComponent],
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	constructor(private http: HttpClient) { }

	ngOnInit(): void {
		const headers = new HttpHeaders().set('Custom-Header', 'HeaderValue');

		this.http
			.get('https://d1wdxa4bufpjmm.cloudfront.net', { headers, responseType: 'blob' })
			.subscribe(
				(response) => {
					console.log('Fonte carregada:', response);
				},
				(error) => {
					console.error('Erro ao carregar fonte:', error);
				}
			);
	}
}
