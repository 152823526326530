import { Routes } from '@angular/router';
import { cadastroResolver } from 'src/app/core/models/resolvers/cadastro.resolver';

export const cadastroRoutes: Routes = [
	{
		path: '',
		resolve: { isPesquisa: cadastroResolver },
		loadComponent: () =>
			import('./cadastro.component').then((m) => m.CadastroComponent),
	},
];
