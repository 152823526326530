import { Routes } from '@angular/router';
import { descadastroRoutes } from './features/descadastro/descadastro-routes';
import { formularioRoutes } from './features/formulario/formulario.routes';
import { mensagemRoutes } from './features/mensagem/mensagem-routes';
import { pesquisaRoutes } from './features/pesquisa/pesquisa.routes';

export const routes: Routes = [
	{
		path: 'f',
		children: formularioRoutes,
	},
	{
		path: 'mensagem',
		children: mensagemRoutes,
	},
	{
		path: '',
		children: pesquisaRoutes,
	},
	{
		path: 'descadastro',
		children: descadastroRoutes,
	},
];
